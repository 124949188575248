import React from 'react';
import { serverSideTranslations } from 'next-i18next/serverSideTranslations';
import { LayoutType } from '@components/layout/LayoutWrapper';
import Login from '@components/login/Login';

const LoginPage = () => <Login />;

export const getServerSideProps = async ({ locale }: { locale: string }) => ({
  props: {
    ...(await serverSideTranslations(locale, ['common', 'notification', 'login', 'forms'])),
  },
});

LoginPage.layout = LayoutType.AUTH;
LoginPage.authPage = true;

export default LoginPage;
