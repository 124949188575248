import React, { useContext, useState } from 'react';
import { Form, Input, Divider } from 'antd';
import UiButton from '@components/ui/button/UiButton';
import makeTypedTranslation from '@hooks/typedTranslations/makeTypedTranslation';
import AuthCard from '@components/ka-ui/auth-card/AuthCard';
import { LoginDto } from '@services/auth/type';
import { AuthContext } from '@contexts/AuthContext';
import { EMAIL_REGEX } from '@utils/regex';
import { useRouter } from 'next/router';
import RoutesEnum from '@constants/routes.enum';
import { AuthLayoutContext, AuthLayoutErrorTypes } from '@contexts/AuthLayoutContext';
import { Trans } from 'next-i18next';
import AuthUserSelect from '@components/ka-ui/auth-user-select/AuthUserSelect';
import styles from './Login.module.scss';

const Login: React.FC = () => {
  const [form] = Form.useForm();
  const router = useRouter();

  const { adminLogin } = useContext(AuthContext);
  const { setErrorMessage } = useContext(AuthLayoutContext);

  const { useTypedTranslation } = makeTypedTranslation('login', 'forms', 'notification', 'common');

  const { t: translate } = useTypedTranslation('login');
  const { t: formsTranslate } = useTypedTranslation('forms');
  const { t: commonTranslate } = useTypedTranslation('common');

  const [formValid, setFormValid] = useState(false);
  const [submitting, setSubmitting] = useState(false);
  const [isEmployee, setIsEmployee] = useState<boolean>();

  const handleFormChange = () => {
    const hasErrors = form.getFieldsError().some(({ errors }) => errors.length);
    setFormValid(!hasErrors && form.isFieldsTouched(true));
  };

  const onCreateAccount = async () => {
    router.push(RoutesEnum.ADMIN_SIGNUP).then(() => setErrorMessage(undefined));
  };

  const onForgotPassword = async () => {
    router
      .push(`${RoutesEnum.ADMIN_FORGOT_PASSWORD}?type=${isEmployee ? 'employee' : 'company'}`)
      .then(() => setErrorMessage(undefined));
  };

  const onFinish = async (data: LoginDto) => {
    setSubmitting(true);
    await adminLogin(data, () => {
      setErrorMessage(AuthLayoutErrorTypes.INVALID_CREDENTIALS);
      setSubmitting(false);
    });
    setSubmitting(false);
  };

  const headerSection = (
    <div className={styles.authTitle}>
      <div className="h5 uppercase">{commonTranslate('authLayout.login_welcome_back')}</div>
      <div className="h4">
        <Trans t={commonTranslate} i18nKey="authLayout.login_choose_account">
          -<span className={styles.headerText}>-</span>
        </Trans>
      </div>
    </div>
  );

  const userState = {
    isEmployee,
    setIsEmployee,
  };

  return (
    <AuthCard header={headerSection}>
      <style>{'.grecaptcha-badge{visibility: visible;}'}</style>
      <section>
        <AuthUserSelect userState={userState} />
        <Form
          form={form}
          layout="vertical"
          size="small"
          data-testid="signup-form"
          onFieldsChange={handleFormChange}
          onFinish={(values) => onFinish({ ...values, is_employee: isEmployee })}
          autoComplete="off"
          className={styles.signUpForm}
        >
          <Form.Item
            label={translate('email')}
            required
            name="email"
            className={isEmployee === undefined ? styles.disabledInput : ''}
            rules={[
              {
                required: true,
                message: formsTranslate('email_required'),
              },
              {
                pattern: EMAIL_REGEX,
                message: formsTranslate('not_valid_email'),
              },
              {
                max: 255,
                message: formsTranslate('max_255_chars_error'),
              },
            ]}
          >
            <Input
              disabled={isEmployee === undefined}
              data-testid="email"
              placeholder={translate('email_placeholder')}
              size="large"
            />
          </Form.Item>
          <Form.Item
            label={
              // eslint-disable-next-line react/jsx-wrap-multilines
              <div className={styles.passwordLabelWrap}>
                <span className={styles.passwordLabel}>{translate('password')}</span>
                <UiButton
                  className={styles.forgotPasswordBtn}
                  type="link"
                  onClick={onForgotPassword}
                  disabled={isEmployee === undefined}
                >
                  {translate('forgot_password')}
                </UiButton>
              </div>
            }
            name="password"
            className={`${styles.passwordFormItem} ${isEmployee === undefined ? styles.disabledInput : ''}`}
            rules={[
              {
                required: true,
                message: formsTranslate('required_minimal'),
              },
            ]}
          >
            <Input.Password
              disabled={isEmployee === undefined}
              data-testid="password"
              placeholder={translate('password_placeholder')}
              size="large"
            />
          </Form.Item>
          <UiButton
            type="primary"
            size="middle"
            htmlType="submit"
            data-testid="submit-button"
            loading={submitting}
            className={styles.submitButton}
            disabled={!formValid || isEmployee === undefined}
          >
            {translate('login')}
          </UiButton>
        </Form>
      </section>
      <Divider className={styles.divider} />
      <section className={styles.footer}>
        <p className="large-body-text title-dark">{translate('sign_up')}</p>
        <UiButton data-testid="create-account-button" type="link" onClick={onCreateAccount}>
          {translate('create_account')}
        </UiButton>
      </section>
    </AuthCard>
  );
};

export default Login;
